.grid {
  display: grid;
  gap: 24px;
  grid-template-areas:
    "main"
    "sidebar";
  grid-template-columns: 1fr;
}

.main {
  grid-area: main;
}

.sidebar {
  grid-area: sidebar;
}

@media (min-width: 768px) {
  .grid {
    grid-template-areas: "main sidebar";
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: 1fr 400px;
  }
}
